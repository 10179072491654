<template>
  <layout-vertical>
    <template #navbar="{ toggleVerticalMenuActive }">
      <div
        class="d-flex flex-grow-1 align-items-center justify-content-between px-1"
      >
        <div class="d-flex flex-row align-items-center">
          <ul class="nav navbar-nav d-xl-none mr-2">
            <li class="nav-item">
              <b-link class="nav-link" @click="toggleVerticalMenuActive">
                <feather-icon icon="MenuIcon" size="21" />
              </b-link>
            </li>
          </ul>

          <div class="d-flex align-items-center ml-1">
            <span class="text-primary mr-2 h1"
              >A<b class="font-weight-bolder">M</b>
            </span>

            <span slot class="text-primary mb-0">
              AREA
              <b class="text-uppercase">{{ user.user.account_type }}</b>
            </span>
          </div>
        </div>

        <b-nav class="align-items-center">
          <b-link v-if="user_data.account_type == 'manager'" :to="null">
            <b-button
              class="ml-2"
              variant="primary"
              @click="$router.push('/pricing')"
              >UPGRADE</b-button
            >
          </b-link>

          <div class="d-flex align-items-center ml-3 mr-1">
            <b-nav-item-dropdown
              v-if="user_data.account_type != 'dealer'"
              class="dropdown-notification mr-1"
              menu-class="dropdown-menu-media"
              right
            >
              <template #button-content>
                <feather-icon
                  badge="0"
                  badge-classes="bg-danger"
                  class="text-body"
                  icon="BellIcon"
                  size="21"
                />
              </template>
              <b-dropdown-item disabled>
                Non ci sono nuove notifiche.
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <div v-if="user.role_name == 'dealer'" class="mx-1">
              <feather-icon
                :badge="cart.cart"
                badge-classes="bg-danger"
                class="text-body cursor-pointer"
                icon="ShoppingCartIcon"
                size="21"
                @click="$router.push('/cart')"
              />
            </div>

            <div class="mx-1">
              <feather-icon
                badge="0"
                badge-classes="bg-primary"
                class="cursor-pointer text-body"
                icon="MessageSquareIcon"
                size="21"
                @click="$router.push('/chat')"
              />
            </div>
          </div>

          <b-nav-item-dropdown
            id="user_menu"
            variant="flat-primary"
            class="dropdown-icon-wrapper"
            right
            menu-class="w-100"
            no-caret
          >
            <template #button-content>
              <div v-if="!$route.meta.backend">
                <div class="d-flex align-items-center">
                  <div class="text-dark">
                    <p class="mb-0 text-right">
                      <b>
                        {{ user.user.user_name }} {{ user.user.user_surname }}
                      </b>
                      <br />
                      {{ user.user.company_name }}
                    </p>
                  </div>

                  <div>
                    <div
                      class="d-flex justify-content-center align-items-center rounded-circle bg-primary ml-1"
                      style="height: 3rem; width: 3rem"
                    >
                      <span class="text-white feather icon-user h2 mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="d-flex align-items-center">
                  <div class="text-dark">
                    <p class="mb-0 text-right">
                      <b>Amministratore</b>
                    </p>
                  </div>

                  <div>
                    <div
                      class="d-flex justify-content-center align-items-center rounded-circle bg-primary ml-1"
                      style="height: 3rem; width: 3rem"
                    >
                      <span class="text-white feather icon-user h2 mb-0"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-nav-item-dropdown>

          <feather-icon
            icon="LogOutIcon"
            size="2x"
            class="text-primary ml-2 mr-1 cursor-pointer"
            @click="logout()"
          />
        </b-nav>
      </div>
    </template>

    <router-view />
  </layout-vertical>
</template>

<script>
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import {
  BButton,
  BLink,
  BNav,
  BNavItemDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BButton,
    LayoutVertical,
    BLink,
    BNav,
    BNavItemDropdown,
    BDropdownItem,
  },

  data: () => ({
    user_data: {},
  }),

  created() {
    this.user_data = JSON.parse(localStorage.getItem("user"));
  },

  computed: {
    ...mapState(["user"]),
    ...mapState(["cart"]),
  },

  methods: {
    logout() {
      this.$cookies.remove("access_token");
      localStorage.removeItem("user");
      this.$router.push("/login");
    },
  },
};
</script>
